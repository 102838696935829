<template>
  <section class="orders">
    <div class="page-header">
      <h3 class="page-title"> Commande </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">e-Commerce</a></li>
          <li class="breadcrumb-item active" aria-current="page">Commande</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- header -->
            <div class="d-flex justify-content-between">
              <h4 class="card-title">Commande</h4>
              <span class="d-flex align-self-center justify-content-enter align-items-center">
                <!-- filtre des status -->
                <b-dropdown  variant="outline-primary" toggle-class="text-decoration-none" no-caret size="sm" class="m-2">
                    <template #button-content>
                        <i class="mdi mdi-filter-variant"></i>
                    </template>
                    <b-dropdown-item @click="selected_status='all'" >Tout</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item href="#" @click="selected_status='preparation'">Préparation</b-dropdown-item>
                    <b-dropdown-item href="#" @click="selected_status='delivery'">Livraison</b-dropdown-item>
                    <b-dropdown-item href="#" @click="selected_status='cancelled'">Annulé</b-dropdown-item>
                </b-dropdown>
                <b-input-group>
                    <b-form-input placeholder="Recherche" v-model="search"></b-form-input>
                </b-input-group>
              </span>
            </div>

            <div class="row overflow-auto">
              <div class="col-12">
                <div v-if="isload" class="jumping-dots-loader">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <b-table 
                :items="filtered_orders" 
                id="table-list" 
                responsive :per-page="perPage" 
                :current-page="currentPage" 
                :fields="fields" 
                :sort-by.sync="sortBy" 
                :sort-desc.sync="sortDesc"
                :filter="search"
                @row-clicked="details"
                >

                  <template #cell(client)="row">
                    <span class="pointer" @click="ordersDetails(row.item.ulid)">{{row.item.user.pseudo}}</span>
                  </template>
                  <template #cell(to)="row">
                    <div v-if="row.item.deliveryinfos">
                      <span v-if="row.item.deliveryinfos.name">{{ row.item.deliveryinfos.name  }} </span>
                      <span v-else> information non fournie </span>
                    </div>
                    <div v-else>
                      information non fournie
                    </div>
                  
                  </template>

                  <template #cell(type)="row">
                    <span v-if="row.item.purchases[0]">
                      <span class="badge badge-outline-default badge-pill" v-if="row.item.purchases[0].product"><i class=" mdi mdi-cart mr-2"></i>Achat</span>
                      <span class="badge badge-outline-default badge-pill" v-else-if="row.item.purchases[0].service"><i class="mdi mdi-calendar mr-2"></i>Réservation</span>
                      <span class="badge badge-outline-default badge-pill" v-else-if="row.item.purchases[0].voucher"><i class="fa fa-gift"></i>Carte Cadeau</span>
                      <span v-else>Vide</span>
                    </span>
                  </template>

                  <template #cell(date)="row">
                    <span v-if="row.item.purchases[0]">{{formatDate2(row.item.purchases[0].datepurchase)}}</span>
                  </template>

                  <template #cell(datestart)="row">
                    <span v-if="row.item.purchases[0]">{{formatDate2(row.item.purchases[0].datestart)}}</span>
                  </template>

                  <template #cell(status)="row">
                      <span class="badge badge-primary" v-if="row.item.status==='Préparation'">Préparation</span>
                      <span class="badge badge-success" v-else-if="row.item.status==='Livraison'">Livraison</span>
                      <span class="badge badge-danger" v-else-if="row.item.status==='cancelled'">Annulé</span>
                  </template>

                  <template #cell(action)="row">
                    <b-button class="btn btn-gradient-danger btn-icon btn-sm" size="sm" @click="deleteOrder(row.item.ulid)"><i class="fa fa-trash"></i></b-button>
                  </template>
                </b-table>
                <!-- message si tableau vide -->
                <h4 v-if="filtered_orders.length<1 && isload==false">Pas d'élément correspondant</h4>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="table-list"
                  align="right">
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios= require ('axios').default
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import { dateMixin } from '../mixins/date';

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
});

export default {
  name:"orders",
  data: function() {
    return {
      perPage: 10,
      isload:true,
      sortBy: 'name',
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      filtered_orders:[],
      selected_status:'all',
      sortable: true,
      search:'',
      fields: [
        // { key: 'slugname', sortable: true },
        { key: 'client', label:'Réservé par', sortable: true },
        { key: 'to', label:'pour', sortable: true },
        // { key: 'dateorder', label:'date de commande', sortable: true },
        // { key: 'datedelivery', label:'date de livraison', sortable: true },
        { key: 'type', sortable: true },
        { key: 'date', label:'fait le',sortable: true },
        { key: 'datestart', label: 'pour le', sortable: true},
        { key: 'status', sortable: true },
        { key: 'action', sortable: true }
      ],
      items: [
      ]
    };
  },
  mixins:[dateMixin],
  computed: {
      rows() {
        return this.filtered_orders.length
      }
    },
  watch:{
    async selected_status(value){
      console.log({value});
        if(value ==='all'){
            this.filtered_orders = this.orders
        } 
        else if(value ==='preparation'){
            this.filtered_orders = this.orders.filter(order =>(
                order.status === 'Préparation'
            ))
        }
        else if(value ==='delivery'){
            this.filtered_orders = this.orders.filter(order =>(
                order.status ==='Livraison'
            ))
        }
        else if(value ==='cancelled'){
            this.filtered_orders = this.orders.filter(order =>(
                order.status ==='cancelled'
            ))
        }
    }
  },
  methods: {
    getOrders() {
      axios.get('orders')
      .then(resOrders=> {
        {
          this.orders= resOrders.data.data
          this.filtered_orders = this.orders
          this.isload = false
        }
      })
      .catch(errOrders=> console.log(errOrders)) 
      },
      details(record){
          this.ordersDetails(record.ulid)
      },
    ordersDetails(ulid) {
      this.$router.push({
        name:"ordersDetails",
        params: {
          ulid: ulid,

        }
      })
    },
    deleteOrder(order){
        this.$swal({
          title: 'Êtes-vous sûr?',
          text: 'Voulez-vous supprimer cette commande ?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, Supprimer',
          cancelButtonText: 'Annuler',
      })
      .then((result) => {
          if (result.value) {
          this.isload = true
          axios.delete(`orders/${order}`, {
            headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token')
                }
              }
              )
              .then(resDelete => {
                console.log(resDelete)
                this.$swal(
                  'Supprimé!',
                  'La commande a bien été supprimée',
                  'success'
                  )   
                this.getOrders()
                this.isload = false
              })
              .catch(errDelete => {console.log(errDelete)})
            }
      })
    }
  },
  async created () {
    this.getOrders()
  }
}
</script>